let i = 0;
const skills: SkillData[] = [
    {
        name: "C#",
        imageURL: "/imgs/skills/CSharp-Logo.png",
        key: i++,
    },
    {
        name: "Java",
        imageURL: "/imgs/skills/Java_logo.png",
        key: i++,
    },
    {
        name: "SQL",
        imageURL: "/imgs/skills/SQL_logo.png",
        key: i++,
    },
    {
        name: "C++",
        imageURL: "/imgs/skills/Cpp-Logo.png",
        key: i++,
    },
    {
        name: "Python",
        imageURL: "/imgs/skills/Python-logo.png",
        key: i++,
    },
    {
        name: "JavaScript",
        imageURL: "/imgs/skills/JavaScript-logo.png",
        key: i++,
    },
    {
        name: "TypeScript",
        imageURL: "/imgs/skills/ts-logo-128.png",
        key: i++,
    },
    {
        name: "HTML",
        imageURL: "/imgs/skills/HTML5_Badge_512.png",
        key: i++,
    },
    {
        name: "Git",
        imageURL: "/imgs/skills/Git-Icon.png",
        key: i++,
    },
    {
        name: "Blazor",
        imageURL: "/imgs/skills/Blazor-Logo.png",
        key: i++,
    },
];

export default skills;
